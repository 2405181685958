@tailwind base;
@tailwind components;
@tailwind utilities;

.service {  background-color: #f9f9f9;
    border: 1px solid #ddd;  border-radius: 5px;
    padding: 20px;  margin-bottom: 20px;
  }
  .service h2 {  color: #333;
    font-size: 1.5rem;  margin-bottom: 10px;
  }
  .service p {  color: #666;
    font-size: 1rem;}

    body.modal-open {
        filter: blur(5px);
      }
      .product-container {
        display: flex;
        flex-wrap: wrap;
      }
      
      .product {
        width: 50%; /* Each product takes up 50% of the container width */
        padding: 10px;
        box-sizing: border-box; /* Include padding and border in the element's total width and height */
      }
     
      .items {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;
    }